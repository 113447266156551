import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { useIsAboveTablet } from '@/hooks/use-breakpoint'
import { isMobileClient } from '@/utils/detect-device'

export function useShouldDevicePlayVideo() {
  const isClientSide = useIsClientSide()
  const isAboveTablet = useIsAboveTablet()
  // ios clients have some issues with zindex and video elements. We can look into fixing that later on
  return isClientSide && isAboveTablet && !isMobileClient()
}
